import React, { createRef } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { ContainerSelect } from "./style";

export default function SelectOption({
  name,
  variant,
  rules,
  errors,
  label,
  children,
  control,
  defaultValue,
}) {
  const labelId = `${name}-label`;
  const ref = createRef();
  return (
    <ContainerSelect>
      <FormControl
        variant={variant ? variant : "outlined"}
        fullWidth
        required={rules?.required ? rules.required : false}
      >
        <InputLabel ref={ref} error={errors[name] ? true : false} id={labelId}>
          {label}
        </InputLabel>
        <Controller
          as={
            <Select
              variant={variant ? variant : "outlined"}
              labelId={labelId}
              label={label}
              error={errors[name] ? true : false}
            >
              {children}
            </Select>
          }
          rules={rules}
          control={control}
          name={name}
          defaultValue={
            defaultValue !== null && defaultValue !== undefined
              ? defaultValue
              : ""
          }
        />
        {errors[name] && errors[name].type === "required" && (
          <FormHelperText error>Campo obrigatório</FormHelperText>
        )}
      </FormControl>
    </ContainerSelect>
  );
}
