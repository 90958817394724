import styled from "styled-components";

export const FormContainer = styled.div`
  .MuiAppBar-colorPrimary {
    position: relative;
    background-color: #2f3a8f;
  }

  form {
    margin-top: 1rem;
  }

  .ck-editor__editable_inline {
    min-height: 500px;
  }
`;

export const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;

  button.btn-cancel {
    margin-right: 1rem;
  }
`;

export const Attachments = styled.div`
  h2 {
    margin-top: 2rem;
  }
  .MuiButton-containedPrimary {
    background-color: #2f3a8f;
  }
  #upload {
    display: none;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
        cursor: pointer;
        color: red;
      }
    }
  }
`;

export const Classes = styled.div`
  h2 {
    margin-top: 2rem;
  }
  button {
    background-color: #2f3a8f;
    color: white;
    margin-top: 1rem;
  }
  button:hover {
    background-color: #2f3a8f;
    color: white;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
