import React, { useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function InputPassword({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  const [type, setType] = useState("password");

  const changeType = () => {
    if (type === "text") {
      setType("password");
    } else {
      setType("text");
    }
  };

  return (
    <Controller
      as={
        <TextField
          variant={variant ? variant : "outlined"}
          fullWidth
          autoComplete="off"
          error={errors[name] ? true : false}
          label={label}
          required={rules && rules.required ? rules.required : false}
          helperText={
            errors[name] && errors[name].type === "required"
              ? "Campo obrigatório"
              : errors[name] && errors[name].type === "validate"
              ? "Campo Inválido"
              : ""
          }
          type={type}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={changeType}
                style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.42)" }}
              >
                {type !== "text" ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            ),
          }}
        ></TextField>
      }
      rules={rules}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
