import styled from "styled-components";

export const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;

  button.btn-cancel {
    margin-right: 1rem;
  }
`;

export const ListUsers = styled.div`
  display: flex;
  border: 1px solid #e6e6e6;
  min-height: 400px;
  max-height: 400px;
  padding: 1rem;
  .search {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    margin-right: 1rem;
    border-right: 1px solid #e6e6e6;
    padding-right: 1rem;

    h3 {
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      padding-bottom: 0.5rem;
    }

    ul {
      max-height: 300px;
      overflow: auto;
      padding-left: 0;

      li {
        padding: 10px;
        min-height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:nth-child(odd) {
          background-color: rgba(230, 230, 230, 0.28);
        }
        svg {
          cursor: pointer;
          color: rgba(0, 0, 0, 0.54);
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
  }
  .associatedUsers {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 1px solid #e6e6e6;

    h3 {
      width: 100%;
      border-bottom: 1px solid #e6e6e6;
      text-align: center;
      padding-bottom: 0.5rem;
    }

    ul {
      max-height: 300px;
      overflow: auto;
      padding-left: 0;

      li {
        min-height: 45px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        &:nth-child(odd) {
          background-color: rgba(230, 230, 230, 0.28);
        }
        svg {
          cursor: pointer;
          color: rgba(0, 0, 0, 0.54);
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
  }
`;
