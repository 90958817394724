import React from "react";
import { Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const AlertError = React.forwardRef(({alert, message}, ref) => (
  <Collapse in={alert}>
    <Alert ref={ref} severity="error">
      {message}
    </Alert>
  </Collapse>
));

export default AlertError;
