import styled from "styled-components";

export const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;

  button.btn-cancel {
    margin-right: 1rem;
  }
`;
