import styled from "styled-components";

export const TableFormContainer = styled.div`
  .MuiTableRow-head {
    background-color: #2f3a8f;
  }

  .MuiTableCell-head {
    color: #e2daf3;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
    .search {
      display: flex;
      align-items: flex-end;
      width: 30%;
      .iconSearch {
        color: #757575;
        margin-right: 5px;
      }
    }
  }

  .status {
    width: 15px;
    height: 15px;
    background: red;
    margin: 0 auto;
    border-radius: 15px;
  }

  @media (max-width: 600px) {
    .header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .search {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;

  .MuiIconButton-root {
    padding: 0 !important;
    margin-right: 5px;
    margin-left: 5px;
  }
  button {
    color: #fff;
    border: 1px solid white;
    background: rgba(0, 0, 0, 0.54);
    &:not(last-child) {
      margin-right: 1rem;
    }
  }
  button:hover {
    color: rgba(0, 0, 0, 0.54);
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.54);
  }
`;

export const NotFoundData = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #757575;
  font-size: 20px;
  font-weigth: bold;
  flex-direction: column;
  .icon {
    font-size: 35px;
    margin-bottom: 0.4rem;
  }
`;
