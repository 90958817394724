import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Button,
  DialogActions,
  IconButton,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  CorrectionContainer,
  LeftSide,
  RightSide,
  CorrectionContent,
  ContentModal,
} from "./style";
import {
  ArrowBack,
  Cancel,
  DeleteForever,
  Edit,
  Save,
  ZoomIn,
  ZoomOut,
  RotateRight,
} from "@material-ui/icons";
import ListFastAnswers from "../ListFastAnswers";
import GradeBox from "../GradeBox";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import API from "../../utils/api";
import { toast } from "react-toastify";
import AnnotationDeleteModal from "../AnnotationDeleteModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { AudioRecorder } from "react-audio-voice-recorder";

export default function ContentCorrectionModal({ data, closeModal, title }) {
  const dispatch = useDispatch();

  const [zoom, setZoom] = useState(0);

  const userLogged = useSelector((state) => state.userLogged.id_group);

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const [showMarks, setShowMarks] = useState(true);
  const [marks, setMarks] = useState([]);

  const [showFastAnswers, setShowFastAnswers] = useState(false);
  const [isPainting, setIsPainting] = useState(false);
  const [mousePosition, setMousePosition] = useState(undefined);
  const [newMousePosition, setNewMousePosition] = useState(undefined);
  const [skillModal, setSkillModal] = useState(false);

  const [selectedSkill, setSelectedSkill] = useState(1);
  const [observationSkill, setObservationSkill] = useState("");
  const [observationType, setObservationType] = useState("texto");
  const [observationAudioSkill, setObservationAudioSkill] = useState(null);

  const [observationStudent, setObservationStudent] = useState(
    data?.OBSERVATION_STUDENT ? data.OBSERVATION_STUDENT : ""
  );
  const [observationBroker, setObservationBroker] = useState(
    data?.OBSERVATION_BROKER ? data.OBSERVATION_BROKER : ""
  );
  const [points, setPoints] = useState([0, 0, 0, 0, 0]);

  const [editedAnnotation, setEditedAnnotation] = useState(null);

  const [deleteModalAnnotation, setDeleteModalAnnotation] = useState({
    status: false,
    annotation: null,
  });

  const [displayCanvans, setDisplayCanvans] = useState(false);

  useEffect(() => {
    const getCorrection = async () => {
      try {
        dispatch(showLoading());

        const res = await API.get(`/posts/buscar-correcao/${data.ID}`);

        setMarks(res.data.marks);
        setPoints(
          res.data.note
            ? JSON.parse("[" + res.data.note + "]")
            : [0, 0, 0, 0, 0]
        );
      } catch (error) {
        toast.error(
          error && error.data && error.data.error
            ? `${error.data.error}`
            : "Ocorreu um erro."
        );
      } finally {
        dispatch(hideLoading());
      }
    };

    getCorrection();
  }, [dispatch, data.ID]);

  const startPaint = useCallback((event) => {
    const coordinates = getCoordinates(event);
    if (coordinates) {
      setMousePosition(coordinates);
      setIsPainting(true);
    }
  }, []);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.addEventListener("mousedown", startPaint);
    return () => {
      canvas.removeEventListener("mousedown", startPaint);
    };
  }, [startPaint]);

  const paint = useCallback(
    (event) => {
      if (isPainting) {
        const coordinates = getCoordinates(event);
        setNewMousePosition(coordinates);

        if (mousePosition && coordinates) {
          drawLine(mousePosition, coordinates);
        }
      }
    },
    [isPainting, mousePosition]
  );

  useEffect(() => {
    if (userLogged !== 3) {
      if (!canvasRef.current) {
        return;
      }
      const canvas = canvasRef.current;
      canvas.addEventListener("mousemove", paint);
      return () => {
        canvas.removeEventListener("mousemove", paint);
      };
    }
  }, [paint, userLogged]);

  const calculateCoordinatesInPercent = useCallback((value, coordinate) => {
    switch (coordinate) {
      case "width":
        return (value / canvasRef?.current?.width) * 100;

      case "height":
        return (value / canvasRef?.current?.height) * 100;

      case "top":
        return (value / canvasRef?.current?.height) * 100;

      case "left":
        return (value / canvasRef?.current?.width) * 100;

      default:
        return value;
    }
  }, []);

  const exitPaint = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height); //clear canvas
    }

    if (
      mousePosition &&
      mousePosition.x &&
      mousePosition.y &&
      newMousePosition &&
      newMousePosition.x &&
      newMousePosition.y
    ) {
      let width = newMousePosition.x - mousePosition.x;
      let height = newMousePosition.y - mousePosition.y;

      if (width && height > 0) {
        setMarks((prev) => [
          ...prev,
          {
            WIDTH: calculateCoordinatesInPercent(width, "width"),
            HEIGHT: calculateCoordinatesInPercent(height, "height"),
            LEFT_SIDE: calculateCoordinatesInPercent(mousePosition.x, "left"),
            TOP: calculateCoordinatesInPercent(mousePosition.y, "top"),
            CLASS: "skill-1",
          },
        ]);
        openModalSkill();
      }
    }

    setIsPainting(false);
    setMousePosition(undefined);
    setNewMousePosition(undefined);
  }, [mousePosition, newMousePosition, calculateCoordinatesInPercent]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    canvas.addEventListener("mouseup", exitPaint);
    canvas.addEventListener("mouseleave", exitPaint);
    return () => {
      canvas.removeEventListener("mouseup", exitPaint);
      canvas.removeEventListener("mouseleave", exitPaint);
    };
  }, [exitPaint]);

  const getCoordinates = (event) => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    var rect = canvas.getBoundingClientRect();

    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  };

  const drawLine = (LastMousePosition, coordinates) => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height); //clear canvas

      context.strokeStyle = "rgb(110 34 103 / 50%)";
      context.fillStyle = "rgb(110 34 103 / 50%)";
      context.lineJoin = "round";
      context.lineWidth = 2;

      context.beginPath();
      let width = coordinates.x - LastMousePosition.x;
      let height = coordinates.y - LastMousePosition.y;
      context.rect(LastMousePosition.x, LastMousePosition.y, width, height);
      context.stroke();
      context.fill();
    }
  };

  const closeModalSkill = (close) => {
    if (!editedAnnotation) {
      if (close) {
        setMarks((prev) => {
          prev[prev.length - 1].CLASS = `skill-${selectedSkill}`;
          prev[prev.length - 1].OBSERVATION = observationAudioSkill
            ? observationAudioSkill
            : observationSkill;
          prev[prev.length - 1].AUDIO = observationAudioSkill ? true : false;

          return prev;
        });
      } else {
        setMarks((prev) => {
          prev.pop();
          return prev;
        });
      }
    } else {
      if (close) {
        const index = marks.findIndex(
          (val) =>
            val.WIDTH === editedAnnotation.WIDTH &&
            val.HEIGHT === editedAnnotation.HEIGHT &&
            val.LEFT_SIDE === editedAnnotation.LEFT_SIDE &&
            val.TOP === editedAnnotation.TOP
        );

        setMarks((prev) => {
          prev[index].CLASS = `skill-${selectedSkill}`;
          prev[index].OBSERVATION = observationAudioSkill
            ? observationAudioSkill
            : observationSkill;
          prev[index].AUDIO = observationAudioSkill ? true : false;

          return prev;
        });

        if (observationAudioSkill) {
          const ref = document.getElementById(`audio-${index}`);
          ref.src = URL.createObjectURL(observationAudioSkill);
        }
      }
    }

    setSkillModal(false);
    setEditedAnnotation(null);
  };

  const openModalSkill = () => {
    setSkillModal(true);
    setObservationSkill("");
    setObservationAudioSkill(null);
    setObservationType("texto");
    setSelectedSkill(1);
  };

  const changeSelectedSkill = (skill) => {
    setSelectedSkill(skill);
  };

  const selectFastAnswer = (text, skill) => {
    setObservationSkill(text);
    setSelectedSkill(skill);
    setShowFastAnswers(false);
  };

  const handleNote = (points) => {
    setPoints(points);
  };

  const save = async () => {
    const body = {
      MARKS: marks,
      NOTE: points.toString(),
      OBSERVATION_BROKER: observationBroker,
    };

    const requestData = new FormData();

    for (const item of body.MARKS) {
      if (item.AUDIO && typeof item.OBSERVATION !== "string") {
        requestData.append("audioFiles", item.OBSERVATION);
      }
    }

    requestData.append("data", JSON.stringify(body));

    try {
      dispatch(showLoading());
      const res = await API.post(
        `/posts/salvar-correcao/${data.ID}`,
        requestData
      );
      toast.success(`${res.data.message}`);
      closeModal();
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
      dispatch(hideLoading());
    }
  };

  const editAnnotation = (annotation) => {
    setEditedAnnotation(annotation);
    switch (annotation.CLASS) {
      case "skill-1":
        setSelectedSkill(1);
        break;

      case "skill-2":
        setSelectedSkill(2);
        break;

      case "skill-3":
        setSelectedSkill(3);
        break;

      case "skill-4":
        setSelectedSkill(4);
        break;

      case "skill-5":
        setSelectedSkill(5);
        break;

      default:
    }

    setSkillModal(true);

    if (annotation.AUDIO) {
      addAudioObservation(annotation.OBSERVATION);
      setObservationSkill("");
      setObservationType("audio");
    } else {
      setObservationSkill(annotation.OBSERVATION);
      setObservationAudioSkill(null);
      setObservationType("texto");
    }
  };

  const deleteAnnotation = (annotation) => {
    const index = marks.findIndex(
      (val) =>
        val.WIDTH === annotation.WIDTH &&
        val.HEIGHT === annotation.HEIGHT &&
        val.LEFT_SIDE === annotation.LEFT_SIDE &&
        val.TOP === annotation.TOP
    );

    const copyMarks = [...marks];

    copyMarks.splice(index, 1);

    setMarks(copyMarks);
  };

  const openDeleteModalAnnotation = (annotation) => {
    setDeleteModalAnnotation({ status: true, annotation });
  };

  const closeDeleteModalAnnotation = (close) => {
    if (close) {
      deleteAnnotation(deleteModalAnnotation.annotation);
    }

    setDeleteModalAnnotation({ status: false, annotation: null });
  };

  const toogleMarks = () => {
    setShowMarks(!showMarks);
  };

  const handleChangeZoom = (event, newValue) => {
    setZoom(newValue);
  };

  const handleChangeObservationType = (event) => {
    setObservationType(event.target.value);
  };

  const addAudioObservation = (blob) => {
    setObservationAudioSkill(blob);

    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;

    setTimeout(() => {
      document.getElementById("audio-list").innerHTML = "";
      document.getElementById("audio-list").appendChild(audio);
    });
  };

  const rotateImage = () => {
    const filterA90 = data.LINK.indexOf("/upload/a_90/");
    const filterA180 = data.LINK.indexOf("/upload/a_180/");
    const filterA270 = data.LINK.indexOf("/upload/a_270/");
    const filterA360 = data.LINK.indexOf("/upload/a_360/");

    if (
      filterA90 === -1 &&
      filterA180 === -1 &&
      filterA270 === -1 &&
      filterA360 === -1
    ) {
      updateUrlLinkImage(data.LINK.replace("/upload/", "/upload/a_90/"));
    } else {
      if (filterA90 >= 0) {
        updateUrlLinkImage(
          data.LINK.replace("/upload/a_90/", "/upload/a_180/")
        );
      }

      if (filterA180 >= 0) {
        updateUrlLinkImage(
          data.LINK.replace("/upload/a_180/", "/upload/a_270/")
        );
      }

      if (filterA270 >= 0) {
        updateUrlLinkImage(
          data.LINK.replace("/upload/a_270/", "/upload/a_360/")
        );
      }

      if (filterA360 >= 0) {
        updateUrlLinkImage(data.LINK.replace("/upload/a_360/", "/upload/"));
      }
    }
  };

  const updateUrlLinkImage = async (newUrl) => {
    try {
      dispatch(showLoading());
      const res = await API.put(`/posts/rotacionar-resposta/${data.ID}`, {
        url: newUrl,
      });
      toast.success(`${res.data.message}`);
      closeModal({ rotate: true, idRotate: data.ID, urlRotate: newUrl });
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
      dispatch(hideLoading());
    }
  };

  return (
    <CorrectionContainer>
      <AppBar>
        <Toolbar>
          <div className="wrapper-data-answer">
            <Typography variant="h6">Correção: {title}</Typography>
            <Typography variant="h6">Tema: {data.THEME}</Typography>
            <Typography variant="h6">Grade: {data.TYPE_COMPOSING}</Typography>
            <Typography variant="h6">Tempo Gasto: {data.TIMER}</Typography>
          </div>

          <div className="wrapper-buttons">
            {userLogged !== 3 && (
              <IconButton component="span" onClick={save}>
                <Tooltip title="Salvar Correção">
                  <Save />
                </Tooltip>
              </IconButton>
            )}
            <IconButton component="span" onClick={() => closeModal()}>
              <Tooltip title="Fechar">
                <Cancel />
              </Tooltip>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <CorrectionContent>
        <LeftSide>
          <div className="wrapper-zoom">
            {userLogged !== 3 && (
              <div className="rotate">
                <Button onClick={rotateImage} startIcon={<RotateRight />}>
                  Rotacionar
                </Button>
              </div>
            )}

            <div className="zoom">
              <ZoomOut />

              <Slider
                size="medium"
                defaultValue={0}
                min={0}
                max={100}
                aria-label="Zoom"
                value={zoom}
                onChange={handleChangeZoom}
                valueLabelDisplay="auto"
              />
              <ZoomIn />
            </div>
          </div>

          <div
            className="view-content"
            style={{ width: `calc(100% + ${zoom}%)` }}
          >
            <img
              id="imageRef"
              onLoad={() => setDisplayCanvans(true)}
              ref={imageRef}
              src={data.LINK}
              alt="Correção"
            />

            <canvas
              style={{
                backgroundImage: `url(${data.LINK})`,
                display: displayCanvans ? "block" : "none",
              }}
              id="canvas"
              ref={canvasRef}
              width={
                document.getElementById("imageRef")?.getBoundingClientRect()
                  .width
              }
              height={
                document.getElementById("imageRef")?.getBoundingClientRect()
                  .height
              }
            />

            {showMarks &&
              marks.map((item, index) => {
                return (
                  <span
                    key={index}
                    className={item.CLASS}
                    style={{
                      width: item.WIDTH + "%",
                      height: item.HEIGHT + "%",
                      top: item.TOP + "%",
                      left: item.LEFT_SIDE + "%",
                    }}
                  >
                    <strong>{index + 1}</strong>
                  </span>
                );
              })}
          </div>
        </LeftSide>
        <RightSide>
          <div className="wrapperTotal">
            <div className="totalGradeBox">
              <span>TOTAL</span>
              <span>
                <strong>{points.reduce((a, b) => a + b, 0)}</strong> PTS
              </span>
            </div>
          </div>

          <GradeBox
            points={points}
            calculateTotalPoints={(points) => handleNote(points)}
          />

          <div className="annotations">
            <div className="wrapper-title-icon">
              <h2>Anotações: </h2>
              <div className="visibility">
                {showMarks ? (
                  <>
                    <Tooltip title="Esconder anotações">
                      <VisibilityOffIcon onClick={toogleMarks} />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Mostrar anotações">
                      <VisibilityIcon onClick={toogleMarks} />
                    </Tooltip>
                  </>
                )}
              </div>
            </div>

            {marks.length > 0 && (
              <div className="notes-list">
                {marks.map((item, index) => {
                  return (
                    <div key={index} className="notes-item">
                      <div className={item.CLASS}>
                        <span>
                          <strong>{index + 1}</strong>
                        </span>
                      </div>

                      {(item.AUDIO_URL || item.AUDIO) && (
                        <audio id={`audio-${index}`} controls>
                          <source
                            src={
                              item.AUDIO_URL ||
                              URL.createObjectURL(item.OBSERVATION)
                            }
                          />
                        </audio>
                      )}

                      {!item.AUDIO && !item.AUDIO_URL && (
                        <span className="observation">{item.OBSERVATION}</span>
                      )}

                      {userLogged !== 3 && (
                        <>
                          <IconButton
                            component="span"
                            size="small"
                            className="actions"
                            onClick={() => editAnnotation(item)}
                          >
                            <Tooltip title="Editar" aria-label="Editar">
                              <Edit />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            size="small"
                            component="span"
                            className="actions"
                            onClick={() => openDeleteModalAnnotation(item)}
                          >
                            <Tooltip title="Deletar" aria-label="Deletar">
                              <DeleteForever />
                            </Tooltip>
                          </IconButton>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="observation-student">
            <h2>Comentário do(a) aluno(a): </h2>
            <textarea
              disabled
              value={observationStudent}
              onChange={(e) => setObservationStudent(e.target.value)}
            ></textarea>
          </div>

          <div className="observation-broker">
            <h2>Parecer da avaliadora: </h2>

            <textarea
              disabled={userLogged === 3 ? true : false}
              value={observationBroker}
              onChange={(e) => setObservationBroker(e.target.value)}
            ></textarea>
          </div>
        </RightSide>
      </CorrectionContent>

      {skillModal && (
        <Dialog
          open={skillModal}
          onClose={(close) => closeModalSkill(close)}
          className="skillModal"
        >
          <DialogTitle className="modal-title-select-skill">
            Anotações - {marks.length}
          </DialogTitle>
          {!showFastAnswers && (
            <>
              <DialogContent>
                <ContentModal>
                  <h2>Competência:</h2>
                  <div className="wrapper-skills">
                    <div
                      onClick={() => changeSelectedSkill(1)}
                      className={
                        selectedSkill === 1 ? "skill-1 active" : "skill-1"
                      }
                    >
                      1
                    </div>
                    <div
                      onClick={() => changeSelectedSkill(2)}
                      className={
                        selectedSkill === 2 ? "skill-2 active" : "skill-2"
                      }
                    >
                      2
                    </div>
                    <div
                      onClick={() => changeSelectedSkill(3)}
                      className={
                        selectedSkill === 3 ? "skill-3 active" : "skill-3"
                      }
                    >
                      3
                    </div>
                    <div
                      onClick={() => changeSelectedSkill(4)}
                      className={
                        selectedSkill === 4 ? "skill-4 active" : "skill-4"
                      }
                    >
                      4
                    </div>
                    <div
                      onClick={() => changeSelectedSkill(5)}
                      className={
                        selectedSkill === 5 ? "skill-5 active" : "skill-5"
                      }
                    >
                      5
                    </div>
                  </div>

                  <FormControl
                    variant="outlined"
                    fullWidth
                    id="observation-type-form-control"
                  >
                    <InputLabel id="observation-type-label">
                      Tipo de observação
                    </InputLabel>
                    <Select
                      labelId="observation-type-label"
                      value={observationType}
                      onChange={handleChangeObservationType}
                    >
                      <MenuItem value="texto">Texto</MenuItem>
                      <MenuItem value="audio">Áudio</MenuItem>
                    </Select>
                  </FormControl>

                  <div className="observation">
                    <h2>Observação:</h2>

                    {observationType === "texto" && (
                      <>
                        <Button onClick={() => setShowFastAnswers(true)}>
                          Respostas Rápidas
                        </Button>
                        <textarea
                          value={observationSkill}
                          onChange={(e) => setObservationSkill(e.target.value)}
                        ></textarea>
                      </>
                    )}

                    {observationType === "audio" && (
                      <>
                        <AudioRecorder
                          onRecordingComplete={(blob) =>
                            addAudioObservation(blob)
                          }
                        />

                        <div id="audio-list"></div>
                      </>
                    )}

                    <div className="actions">
                      <DialogActions>
                        <Button
                          className="btn-cancel-modal"
                          onClick={() => closeModalSkill()}
                        >
                          CANCELAR
                        </Button>
                        <Button
                          className="btn-save-modal"
                          onClick={() => closeModalSkill(true)}
                        >
                          APLICAR
                        </Button>
                      </DialogActions>
                    </div>
                  </div>
                </ContentModal>
              </DialogContent>
            </>
          )}

          {showFastAnswers && (
            <ContentModal>
              <DialogContent>
                <div className="titleFastAnswers">
                  <ArrowBack onClick={() => setShowFastAnswers(false)} />
                  <h2>Repostas Rápidas:</h2>
                </div>

                <ListFastAnswers
                  setObservation={(text, skill) =>
                    selectFastAnswer(text, skill)
                  }
                />
              </DialogContent>
            </ContentModal>
          )}
        </Dialog>
      )}

      {deleteModalAnnotation && deleteModalAnnotation.status && (
        <Dialog open={deleteModalAnnotation.status}>
          <AnnotationDeleteModal
            closeModal={(close) => closeDeleteModalAnnotation(close)}
          />
        </Dialog>
      )}
    </CorrectionContainer>
  );
}
