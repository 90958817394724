import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

export default function InputDateTime({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  return (
    <Controller
      as={
        <InputMask
          mask="99/99/9999 99:99:99"
          value={
            control.getValues(`${name}`)
              ? control.getValues(`${name}`)
              : defaultValue
          }
          disabled={false}
          maskChar="_"
        >
          {() => (
            <TextField
              variant={variant ? variant : "outlined"}
              fullWidth
              autoComplete="off"
              required={rules && rules.required ? rules.required : false}
              error={errors[name] ? true : false}
              label={label}
              helperText={
                errors[name] && errors[name].type === "required"
                  ? "Campo obrigatório"
                  : ""
              }
              type="text"
            ></TextField>
          )}
        </InputMask>
      }
      rules={rules}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
