import React from "react";
import { WrapperProfileMinified } from "./style";
import logoMinified from "../../assets/images/logo-minified.png";

export default function ProfileMinified() {
  return (
    <WrapperProfileMinified>
      <img src={logoMinified} alt="Ligando Ideias" />
    </WrapperProfileMinified>
  );
}
