import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { FormContainer, FormActions, Attachments, Classes } from "./style";
import ButtonCancel from "../../components/ButtonCancel";
import ButtonSave from "../../components/ButtonSave";
import { useForm } from "react-hook-form";
import InputText from "../InputText";
import SelectOption from "../Select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CloudinaryImageUploadAdapter } from "ckeditor-cloudinary-uploader-adapter";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";
import InputDateTime from "../inputDateTime";
import { toast } from "react-toastify";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { showLoading, hideLoading } from "../../store/Loading/actions";
import { useDispatch, useSelector } from "react-redux";
import API from "../../utils/api";
import moment from "moment";

export default function FormPosts({ post, close }) {
  const { control, handleSubmit, errors, reset, watch } = useForm({
    mode: "all",
  });

  const watchTypePost = watch("type_post");

  const userLogged = useSelector((state) => state.userLogged);
  const [item, setItem] = useState({
    TITLE: "",
    ID_TYPE_POST: 1,
    LIMIT_DATE: "",
    CONTENT: "",
  });
  const [files, setFiles] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState({});
  const [editorData, setEditorData] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const getAllClasses = async () => {
      dispatch(showLoading());
      try {
        const res = await API.get("/turmas/todas");

        setClasses(res.data);
      } catch (error) {
        toast.error(
          error && error.data && error.data.error
            ? `${error.data.error}`
            : "Ocorreu um erro."
        );
      } finally {
        dispatch(hideLoading());
      }
    };

    reset();
    if (post) {
      setItem(post);

      if (post.CLASSES) {
        let obj = {};
        for (const item of post.CLASSES) {
          obj[item.ID_CLASS] = true;
        }
        setSelectedClasses(obj);
      }
    }

    getAllClasses();
    document
      .getElementsByClassName("MuiDialog-container")[0]
      .setAttribute("tabIndex", "");
  }, [dispatch, reset, post]);

  const onSubmit = (data) => {
    const filtered = Object.fromEntries(
      Object.entries(selectedClasses).filter(([key, value]) => value === true)
    );
    if (Object.keys(filtered).length > 0) {
      dispatch(showLoading());

      data.content = editorData;
      data.created_by = userLogged.id_user;
      data.classes = filtered;

      let formData = new FormData();
      for (let file of files) {
        formData.append("files", file);
      }

      formData.append("data", JSON.stringify(data));

      if (!item.hasOwnProperty("ID")) {
        create(formData);
      } else {
        update(formData, item.ID);
      }
    } else {
      toast.error(`Preencher pelo menos uma turma.`);
    }
  };

  const create = async (formData) => {
    try {
      const res = await API.post("/posts", formData);
      toast.success(`${res.data.message}`);
      close();
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const update = async (formData, id) => {
    try {
      const res = await API.put(`/posts/${id}`, formData);
      toast.success(`${res.data.message}`);
      close();
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const deleteFile = (file) => {
    const filter = files.filter((val) => val.name !== file.name);
    setFiles(filter);
    document.getElementById("upload").value = "";
  };

  const deleteFileInPost = async (file) => {
    const filter = item.FILES.filter((val) => val.ID !== file.ID);
    try {
      dispatch(showLoading());
      await API.delete(`/posts/arquivo/${file.ID}`);
      setItem({
        ...item,
        FILES: filter,
      });
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onChangeFile = (file) => {
    if (file) {
      const filter = files.filter(
        (val) => (val.name || val.NAME) === file.name
      );
      if (filter.length === 0) {
        if (file.size < 10485760) {
          setFiles([...files, file]);
          document.getElementById("upload").value = "";
        } else {
          toast.error(`Arquivo muito grande.`);
        }
      } else {
        toast.error(`Já existe um arquivo com esse nome.`);
      }
    }
  };

  const changeSelectedClasses = (event) => {
    setSelectedClasses({
      ...selectedClasses,
      [event.target.name]: event.target.checked,
    });
  };

  const markAllIndividualClasses = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("aula individual")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllNightClasses = () => {
    let obj = {};
    for (let item of classes.filter(
      (val) =>
        val.NAME.toLowerCase().includes("turma noturna") ||
        val.NAME.toLowerCase().includes("niquelândia")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllMentorships = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("mentoria")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  const markAllHumanizedCorrections = () => {
    let obj = {};
    for (let item of classes.filter((val) =>
      val.NAME.toLowerCase().includes("correção")
    )) {
      obj[item.ID] = true;
    }

    const oldSelectedClasses = selectedClasses;

    setSelectedClasses({ ...oldSelectedClasses, ...obj });
  };

  return (
    <FormContainer>
      <AppBar>
        <Toolbar>
          <Typography variant="h6">
            {post?.ID ? "Editar post" : "Adicionar post"}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={8}>
              <InputText
                name="title"
                label="Título"
                control={control}
                defaultValue={post?.TITLE ? post.TITLE : item?.TITLE}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <SelectOption
                id="type_post"
                name="type_post"
                label="Tipo do Post"
                control={control}
                defaultValue={
                  post?.ID_TYPE_POST ? post.ID_TYPE_POST : item?.ID_TYPE_POST
                }
                rules={{ required: true }}
                errors={errors}
              >
                <MenuItem value={1}>Normal</MenuItem>
                <MenuItem value={2}>Atividade</MenuItem>
              </SelectOption>
            </Grid>
          </Grid>

          {(watchTypePost === 2 ||
            (item?.ID_TYPE_POST === 2 &&
              (watchTypePost === undefined || watchTypePost === null))) && (
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                <InputDateTime
                  name="limit_date"
                  label="Data de entrega"
                  control={control}
                  defaultValue={
                    item.LIMIT_DATE !== ""
                      ? moment(item?.LIMIT_DATE).format("DD/MM/YYYY HH:mm:ss")
                      : ""
                  }
                  rules={{ required: true }}
                  errors={errors}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  language: "pt-br",
                }}
                data={post?.CONTENT ? post.CONTENT : item?.CONTENT}
                onReady={(editor) => {
                  if (editor) {
                    if (post?.CONTENT) {
                      setEditorData(post.CONTENT);
                    }

                    editor.plugins.get("FileRepository").createUploadAdapter = (
                      loader
                    ) => {
                      return new CloudinaryImageUploadAdapter(
                        loader,
                        "dlnccaqps",
                        "dac2l8vw"
                      );
                    };
                    editor.locale = "ptBR";
                  }
                }}
                onChange={(event, editor) => {
                  if (editor) {
                    setEditorData(editor.getData());
                  }
                }}
              />
            </Grid>
          </Grid>
          <Attachments>
            <h2>Anexos:</h2>
            <input
              onChange={(e) => onChangeFile(e.target.files[0])}
              accept="*"
              id="upload"
              name="upload"
              type="file"
            />
            <label htmlFor="upload">
              <Button variant="contained" color="primary" component="span">
                Adicionar anexos
              </Button>
            </label>
            <ul>
              {item.FILES &&
                item.FILES.map((file) => {
                  return (
                    <li key={file.NAME}>
                      <HighlightOff onClick={() => deleteFileInPost(file)} />
                      <a rel="noreferrer" target="_blank" href={file.LINK}>
                        {file.NAME}
                      </a>
                    </li>
                  );
                })}
              {files.map((file) => {
                return (
                  <li key={file.name}>
                    <HighlightOff onClick={() => deleteFile(file)} />
                    {file.name}
                  </li>
                );
              })}
            </ul>
            <hr />
          </Attachments>

          <Classes>
            <div>
              <h2>Aulas individuais: </h2>

              {classes.length > 0 &&
                classes
                  .filter(function (val) {
                    return val.NAME.toLowerCase().includes("aula individual");
                  })
                  .map(function (item) {
                    return (
                      <FormControlLabel
                        key={item.ID}
                        control={<Checkbox name={`${item.ID}`} />}
                        checked={selectedClasses[`${item.ID}`] ? true : false}
                        onChange={changeSelectedClasses}
                        label={item.NAME}
                      />
                    );
                  })}

              <br />

              <Button onClick={markAllIndividualClasses}>Marcar todas</Button>

              <h2>Turmas noturnas: </h2>

              {classes.length > 0 &&
                classes
                  .filter(function (val) {
                    return (
                      val.NAME.toLowerCase().includes("turma noturna") ||
                      val.NAME.toLowerCase().includes("niquelândia")
                    );
                  })
                  .map(function (item) {
                    return (
                      <FormControlLabel
                        key={item.ID}
                        control={<Checkbox name={`${item.ID}`} />}
                        checked={selectedClasses[`${item.ID}`] ? true : false}
                        onChange={changeSelectedClasses}
                        label={item.NAME}
                      />
                    );
                  })}

              <br />

              <Button onClick={markAllNightClasses}>Marcar todas</Button>

              <h2>Mentorias: </h2>

              {classes.length > 0 &&
                classes
                  .filter(function (val) {
                    return val.NAME.toLowerCase().includes("mentoria");
                  })
                  .map(function (item) {
                    return (
                      <FormControlLabel
                        key={item.ID}
                        control={<Checkbox name={`${item.ID}`} />}
                        checked={selectedClasses[`${item.ID}`] ? true : false}
                        onChange={changeSelectedClasses}
                        label={item.NAME}
                      />
                    );
                  })}

              <br />

              <Button onClick={markAllMentorships}>Marcar todas</Button>

              <h2>Correções humanizadas: </h2>

              {classes.length > 0 &&
                classes
                  .filter(function (val) {
                    return val.NAME.toLowerCase().includes("correção");
                  })
                  .map(function (item) {
                    return (
                      <FormControlLabel
                        key={item.ID}
                        control={<Checkbox name={`${item.ID}`} />}
                        checked={selectedClasses[`${item.ID}`] ? true : false}
                        onChange={changeSelectedClasses}
                        label={item.NAME}
                      />
                    );
                  })}

              <br />

              <Button onClick={markAllHumanizedCorrections}>
                Marcar todas
              </Button>
            </div>
          </Classes>

          <FormActions>
            <ButtonCancel click={() => close(true)} />
            <ButtonSave />
          </FormActions>
        </form>
      </DialogContent>
    </FormContainer>
  );
}
