import React, { useCallback, useEffect, useState } from "react";
import Title from "../../components/Title";
import { ContainerSchedule, Day, Time } from "./style";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PersonIcon from "@material-ui/icons/Person";
import { Dialog, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TodayIcon from "@material-ui/icons/Today";
import API from "../../utils/api";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { useDispatch } from "react-redux";
import ButtonAdd from "../../components/ButtonAdd";
import FormSchedule from "../../components/FormSchedule";
import DeleteScheduleModal from "../../components/DeleteScheduleModal";

export default function Schedule() {
  const dispatch = useDispatch();

  const [schedule, setSchedule] = useState({});

  const [showForm, setShowForm] = useState({ status: false });

  const [deleteScheduleModal, setDeleteScheduleModal] = useState({
    status: false,
  });

  const getSchedule = useCallback(async () => {
    dispatch(showLoading());
    try {
      const res = await API.get("horario/listar");

      setSchedule(res?.data ? res.data : {});
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch]);

  useEffect(() => {
    getSchedule();
  }, [getSchedule]);

  const addSchedule = () => {
    setShowForm({
      status: true,
    });
  };

  const editSchedule = (schedule) => {
    setShowForm({
      status: true,
      data: schedule,
    });
  };

  const closeForm = () => {
    setShowForm({ status: false });
    getSchedule();
  };

  const openModalDeleteSchedule = (schedule) => {
    setDeleteScheduleModal({ status: true, idItem: schedule.ID });
  };

  const closeDeleteScheduleModal = (close) => {
    if (close) {
      deleteSchedule(deleteScheduleModal.idItem);
    } else {
      setDeleteScheduleModal({ status: false, idItem: null });
    }
  };

  const deleteSchedule = async (id) => {
    dispatch(showLoading());

    try {
      const res = await API.delete(`/horario/${id}`);

      toast.success(`${res.data.message}`);

      setDeleteScheduleModal({ status: false, idItem: null });
      getSchedule();
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  return (
    <>
      <Title name="Horário" />

      <ContainerSchedule>
        {showForm?.status && (
          <FormSchedule closeForm={closeForm} schedule={showForm.data} />
        )}

        {deleteScheduleModal && deleteScheduleModal.status && (
          <Dialog open={deleteScheduleModal.status}>
            <DeleteScheduleModal
              closeModal={(close) => closeDeleteScheduleModal(close)}
            />
          </Dialog>
        )}

        {!showForm?.status && (
          <>
            <p>
              🚀 <strong>Aulas individuais</strong>
            </p>
            <p>
              🚀 <strong>Turmas noturnas</strong>
            </p>
            <p>
              🚀 <strong>Mentorias</strong>
            </p>

            <div className="wrapper-btn-add">
              <ButtonAdd click={addSchedule} />
            </div>

            <Day>
              <p>
                <TodayIcon />
                <strong>Segunda-feira:</strong>
              </p>

              {schedule?.monday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.monday || schedule?.monday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>

            <Day>
              <p>
                <TodayIcon />
                <strong>Terça-feira:</strong>
              </p>

              {schedule?.tuesday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.tuesday || schedule?.tuesday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>

            <Day>
              <p>
                <TodayIcon />
                <strong>Quarta-feira:</strong>
              </p>

              {schedule?.wednesday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.wednesday || schedule?.wednesday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>

            <Day>
              <p>
                <TodayIcon />
                <strong>Quinta-feira:</strong>
              </p>

              {schedule?.thursday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.thursday || schedule?.thursday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>

            <Day>
              <p>
                <TodayIcon />
                <strong>Sexta-feira:</strong>
              </p>

              {schedule?.friday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.friday || schedule?.friday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>

            <Day>
              <p>
                <TodayIcon />
                <strong>Sábado:</strong>
              </p>

              {schedule?.saturday?.map((item) => (
                <Time key={item.ID}>
                  <div className="actions">
                    <Tooltip title="Editar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => editSchedule(item)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Deletar horário">
                      <IconButton
                        color="primary"
                        component="span"
                        onClick={() => openModalDeleteSchedule(item)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>

                  <span>
                    <ScheduleIcon />
                    {item.DESCRIPTION_TIME}
                  </span>
                  <span>
                    <PersonIcon />
                    {item.DESCRIPTION_STUDENT.toLowerCase().indexOf("vago") >=
                    0 ? (
                      <strong>{item.DESCRIPTION_STUDENT}</strong>
                    ) : (
                      <>{item.DESCRIPTION_STUDENT}</>
                    )}
                  </span>
                </Time>
              ))}

              {(!schedule?.saturday || schedule?.saturday?.length === 0) && (
                <>
                  <span>Sem horário definido</span>
                </>
              )}
            </Day>
          </>
        )}
      </ContainerSchedule>
    </>
  );
}
