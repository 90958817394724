import styled from "styled-components";

export const NotificationContainer = styled.div`
  .MuiDialogTitle-root {
    background: #2f3a8f;

    h2 {
      width: 100%;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        color: white;
      }
    }
  }

  .success {
    color: green;
  }

  .error {
    color: red;
  }
`;
