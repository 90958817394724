import axios from "axios";

const http = () => {
  let instance = axios.create({
    baseURL: process.env.REACT_APP_API,
  });

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        error.response.status === 401 &&
        error.response.config.url !== "/auth/login"
      ) {
        localStorage.clear();
        window.location.href = "/entrar";
      }

      return Promise.reject(error.response);
    }
  );

  return instance;
};

export default http();
