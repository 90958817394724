import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { valueNotEmpty } from "../../validators/blank";

export default function InputText({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  variant,
  placeholder,
}) {
  return (
    <Controller
      as={
        <TextField
          variant={variant ? variant : "outlined"}
          fullWidth
          autoComplete="off"
          error={errors[name] ? true : false}
          required={rules && rules.required ? rules.required : false}
          label={label}
          placeholder={placeholder}
          helperText={
            errors[name] && errors[name].type === "required"
              ? "Campo obrigatório"
              : errors[name] && errors[name].type === "validate"
              ? "Campo em branco"
              : ""
          }
          type="text"
        ></TextField>
      }
      rules={{
        ...rules,
        validate: (value) => valueNotEmpty(value, control, name),
      }}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
