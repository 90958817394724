import {
  Button,
  ButtonGroup,
  debounce,
  Dialog,
  Icon,
  IconButton,
  Paper,
  Slide,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  Attachment,
  InsertDriveFile,
  BorderColor,
  EventNote,
  AddCircle,
  Bookmarks,
  Edit,
  DeleteForever,
  PeopleOutline,
  ListAlt,
  PlaylistAddCheck,
  PlaylistAdd,
} from "@material-ui/icons";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import FormPosts from "../../components/FormPosts";
import Title from "../../components/Title";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import API from "../../utils/api";
import {
  ListPosts,
  ContentPost,
  HeaderPost,
  Post,
  AttachmentPost,
  ContainerPosts,
  Counter,
} from "./style";
import moment from "moment";
import ContentDeleteModalPost from "../../components/ContentDeleteModalPost";
import ContentAnswerModal from "../../components/ContentAnswerModal";
import { Link } from "react-router-dom";
import ContentCorrectionModal from "../../components/ContentCorrectionModal";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import ContentNotificationStudentsModal from "../../components/ContentNotificationStudentsModal";

export default function Posts() {
  const dispatch = useDispatch();

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const TransitionDown = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const userLogged = useSelector((state) => state.userLogged.id_group);
  const userClass = useSelector((state) => state.userLogged.id_class);
  const [formStatus, setOpenForm] = useState(false);
  const [posts, setPosts] = useState([]);
  const [postItem, setPostItem] = useState({});
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [typeFilter, setTypeFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState({
    idPost: null,
    status: false,
  });
  const [showAnswerModal, setAnswerModal] = useState({
    idPost: null,
    status: false,
  });

  const [modalCorrection, setModalCorrection] = useState({
    data: null,
    status: false,
  });

  const [modalNotificationStudents, setModalNotificationStudents] = useState({
    data: null,
    status: false,
  });

  const getPosts = useCallback(async () => {
    dispatch(showLoading());
    try {
      const params = {
        page,
        typeFilter,
        search,
      };

      const res = await API.get("/posts", { params });

      if (page !== 0) {
        setPosts((prevPosts) => {
          return [...prevPosts, ...res.data.data];
        });
      } else {
        setPosts(res.data.data);
      }

      setTotal(res.data.total);

      if (posts.length === res.data.total) {
        setHasMore(false);
      }
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
    // eslint-disable-next-line
  }, [dispatch, page, typeFilter, search]);

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const handleOnDocumentBottom = useCallback(() => {
    if (posts.length > 0 && hasMore === true && !isMobile) {
      handleNextPage();
    }
  }, [hasMore, posts.length]);

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
  };

  useBottomScrollListener(handleOnDocumentBottom);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const openForm = () => {
    setOpenForm(true);
  };

  const closeForm = (close) => {
    setOpenForm(false);
    if (!close) {
      setHasMore(true);

      if (page === 0) {
        getPosts();
      }

      setPage(0);
    }
  };

  const onFilter = debounce((text) => {
    setHasMore(true);
    setPage(0);
    setSearch(text);
  }, 700);

  const onChangeTypeFilter = (type) => {
    setHasMore(true);
    setPage(0);
    setTypeFilter(type);
  };

  const editPost = (post) => {
    setPostItem(post);
    openForm();
  };

  const notifyCorrectedEssays = async (post) => {
    try {
      const data = {
        post: post.ID,
      };

      dispatch(showLoading());
      const res = await API.post(`/notificar-alunos/redacoes-corrigidas`, data);
      openNotificationStudentsModal(res.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const notifyNewPost = async (post) => {
    try {
      const data = {
        post: post.ID,
      };

      dispatch(showLoading());
      const res = await API.post(`/notificar-alunos/novo-post`, data);
      openNotificationStudentsModal(res.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const addPost = () => {
    setPostItem(null);
    openForm();
  };

  const deletePost = async (idPost) => {
    try {
      dispatch(showLoading());
      const res = await API.delete(`/posts/${idPost}`);
      toast.success(`${res.data.message}`);
      if (page === 0) {
        getPosts();
      }

      setPage(0);
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const openModalDeletePost = (idPost) => {
    setShowDeleteModal({
      idPost,
      status: true,
    });
  };

  const closeDeleteModal = (close) => {
    if (close) {
      deletePost(showDeleteModal.idPost);
    }

    setShowDeleteModal({
      idPost: null,
      status: false,
    });
  };

  const openModalAnswer = (post) => {
    setAnswerModal({
      post,
      status: true,
    });
  };

  const closeAnswerModal = (close) => {
    if (close) {
      setPosts((prev) => {
        const index = prev.findIndex(
          (val) => val.ID === showAnswerModal.post.ID
        );

        if (index >= 0) {
          prev[index].TOTAL_ANSWERS = 1;
        }

        return prev;
      });
    }

    setAnswerModal({
      post: null,
      status: false,
    });
  };

  const openModalCorrection = (title, data) => {
    setModalCorrection({
      data,
      title,
      status: true,
    });
  };

  const closeModalCorrection = () => {
    setModalCorrection({
      data: null,
      status: false,
    });
  };

  const openNotificationStudentsModal = (data) => {
    setModalNotificationStudents({
      data: data,
      status: true,
    });
  };

  const closeNotificationStudentsModal = () => {
    setModalNotificationStudents({
      data: null,
      status: false,
    });
  };

  const getDataAnswer = async (post) => {
    try {
      dispatch(showLoading());
      const res = await API.get(`/posts/buscar-dados-reposta/post/${post.ID}`);
      openModalCorrection(post.TITLE, res.data);
    } catch (error) {
      dispatch(hideLoading());
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  return (
    <ContainerPosts>
      <Title name="Posts" />
      {userLogged && userLogged === 1 && (
        <div className="btn-add-post">
          <Button onClick={addPost}>
            <AddCircle /> <span>Criar Post</span>
          </Button>
        </div>
      )}

      {formStatus && (
        <Dialog
          fullScreen
          open={formStatus}
          onClose={closeForm}
          TransitionComponent={Transition}
        >
          <FormPosts post={postItem} close={(close) => closeForm(close)} />
        </Dialog>
      )}

      <div className="filter">
        <div className="wrapperButtonGroup">
          <span>Filtro:</span>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={() => onChangeTypeFilter("all")}>
              <span className={typeFilter === "all" ? "actived" : "none"}>
                TODOS
              </span>
            </Button>
            <Button onClick={() => onChangeTypeFilter("normal")}>
              <span className={typeFilter === "normal" ? "actived" : "none"}>
                NORMAL
              </span>
            </Button>
            <Button onClick={() => onChangeTypeFilter("activity")}>
              <span className={typeFilter === "activity" ? "actived" : "none"}>
                ATIVIDADE
              </span>
            </Button>
          </ButtonGroup>
        </div>

        <div className="search">
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Pesquisar Post"
            onChange={(e) => onFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="banner"></div>
      <Counter>
        <Bookmarks />
        <span>
          {total} {total === 1 ? "publicação" : "publicações"}
        </span>
      </Counter>

      <ListPosts>
        {posts.length > 0 &&
          posts.map((post, index) => {
            return (
              <Post
                key={`${post.ID}-${index}`}
                className={
                  post.ID_TYPE_POST === 1 ? "posts normal" : "posts activity"
                }
              >
                <Paper>
                  <HeaderPost>
                    <div className="author-post">
                      <div className="data-post">
                        {!post.PHOTO && (
                          <div className="imgIcon">
                            <Icon color="action">face</Icon>
                          </div>
                        )}

                        {post.PHOTO && (
                          <div
                            className="imgUser"
                            style={{
                              backgroundImage: `url(${post.PHOTO})`,
                            }}
                          />
                        )}

                        <h3>{post.AUTHOR}</h3>
                        <span className="date-created">
                          {moment.utc(post.CREATED).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div className="actions-author">
                        {userLogged &&
                          userLogged === 1 &&
                          post.ID_TYPE_POST === 2 && (
                            <>
                              <IconButton onClick={() => notifyNewPost(post)}>
                                <Tooltip
                                  title="Notificar alunos sobre nova postagem"
                                  aria-label="Notificar alunos sobre nova postagem"
                                >
                                  <PlaylistAdd />
                                </Tooltip>
                              </IconButton>

                              <IconButton
                                onClick={() => notifyCorrectedEssays(post)}
                              >
                                <Tooltip
                                  title="Notificar alunos sobre redações corrigidas"
                                  aria-label="Notificar alunos sobre redações corrigidas"
                                >
                                  <PlaylistAddCheck />
                                </Tooltip>
                              </IconButton>
                            </>
                          )}

                        {userLogged && userLogged === 1 && (
                          <>
                            <IconButton onClick={() => editPost(post)}>
                              <Tooltip title="Editar" aria-label="Editar">
                                <Edit />
                              </Tooltip>
                            </IconButton>

                            <IconButton
                              onClick={() => openModalDeletePost(post.ID)}
                            >
                              <Tooltip title="Deletar" aria-label="Deletar">
                                <DeleteForever />
                              </Tooltip>
                            </IconButton>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={
                        post.ID_TYPE_POST === 1
                          ? "type-post normal"
                          : "type-post activity"
                      }
                    >
                      <div className="type-post-left">
                        {post.ID_TYPE_POST === 1 ? (
                          <EventNote />
                        ) : (
                          <BorderColor />
                        )}
                        <div className="title-post">
                          <span>
                            {post.ID_TYPE_POST === 1
                              ? "Publicação"
                              : "Atividade"}
                          </span>
                          <strong>{post.TITLE}</strong>
                        </div>
                      </div>
                      {post.ID_TYPE_POST === 2 && (
                        <div className="type-post-right">
                          <span className="date">
                            Entregar até:
                            <strong>
                              {moment(post.LIMIT_DATE).format(
                                "DD/MM/YYYY - HH:mm:ss"
                              )}
                            </strong>
                          </span>

                          {userLogged && userLogged !== 3 && (
                            <div className="wrapper-count-answers">
                              {post.POST_CORRECTED && <EmojiEventsIcon />}
                              <Button>
                                <Link to={`/respostas/post/${post.ID}`}>
                                  <div className="titleAnswers">
                                    <PeopleOutline className="iconPeople" />
                                    Ver Respostas
                                  </div>
                                  <div className="totalAnswers">
                                    <span>{post.TOTAL_ANSWERS}</span>
                                  </div>
                                </Link>
                              </Button>
                            </div>
                          )}

                          {userLogged &&
                            userLogged === 3 &&
                            (post.NOTE ? (
                              <Button onClick={() => getDataAnswer(post)}>
                                <div className="titleAnswers">
                                  <ListAlt className="iconList" />
                                  Ver Correção
                                </div>
                              </Button>
                            ) : (
                              <Button onClick={() => openModalAnswer(post)}>
                                <div className="titleAnswers">
                                  <ListAlt className="iconList" />
                                  Sua Resposta
                                </div>
                                <div className="totalAnswers">
                                  <span>{post.TOTAL_ANSWERS}</span>
                                </div>
                              </Button>
                            ))}
                        </div>
                      )}
                    </div>
                  </HeaderPost>
                  <ContentPost>
                    <div className="classes-list">
                      {userLogged &&
                        userLogged !== 3 &&
                        post.CLASSES.map((item) => {
                          return (
                            <span key={`class-${post.ID}-${item.ID_CLASS}`}>
                              {item.NAME}
                            </span>
                          );
                        })}

                      {userLogged &&
                        userLogged === 3 &&
                        post.CLASSES.map((item) =>
                          item.ID_CLASS === userClass ? (
                            <span key={`class-${post.ID}-${item.ID_CLASS}`}>
                              {item.NAME}
                            </span>
                          ) : null
                        )}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: post.CONTENT }} />
                  </ContentPost>
                  {post.FILES.length > 0 && (
                    <AttachmentPost>
                      <div className="counter">
                        <Attachment />
                        <span>Anexos</span>
                      </div>
                      <div className="list-attachments">
                        {post.FILES.map((item) => {
                          return (
                            <a
                              key={`file-${post.ID}-${item.ID}`}
                              href={item.LINK}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <InsertDriveFile />
                              <span>{item.NAME}</span>
                            </a>
                          );
                        })}
                      </div>
                    </AttachmentPost>
                  )}
                </Paper>
              </Post>
            );
          })}
      </ListPosts>

      {isMobile && hasMore && (
        <div
          className="btn-show-more"
          style={isMobile ? { marginBottom: "1rem" } : ""}
        >
          <Button onClick={handleNextPage}>Ver Mais</Button>
        </div>
      )}

      {modalCorrection && modalCorrection.status && (
        <Dialog
          fullScreen
          open={modalCorrection.status}
          TransitionComponent={Transition}
        >
          <ContentCorrectionModal
            title={modalCorrection.title.toUpperCase()}
            data={modalCorrection.data}
            closeModal={(close) => closeModalCorrection(close)}
          ></ContentCorrectionModal>
        </Dialog>
      )}

      {showDeleteModal && showDeleteModal.status && (
        <Dialog
          TransitionComponent={TransitionDown}
          open={showDeleteModal.status}
        >
          <ContentDeleteModalPost
            closeModal={(close) => closeDeleteModal(close)}
          />
        </Dialog>
      )}

      {showAnswerModal && showAnswerModal.status && (
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          open={showAnswerModal.status}
        >
          <ContentAnswerModal
            post={showAnswerModal.post}
            closeModal={(close) => closeAnswerModal(close)}
          />
        </Dialog>
      )}

      {modalNotificationStudents && modalNotificationStudents.status && (
        <Dialog
          TransitionComponent={TransitionDown}
          open={modalNotificationStudents.status}
        >
          <ContentNotificationStudentsModal
            data={modalNotificationStudents.data}
            closeModal={closeNotificationStudentsModal}
          />
        </Dialog>
      )}
    </ContainerPosts>
  );
}
