import styled from "styled-components";

export const WrapperSave = styled.div`
  button {
    min-width: 120px;
    color: #fff;
    background-color: green;
  }
  button:hover {
    color: #fff;
    background-color: green;
  }
`;
