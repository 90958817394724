import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function DeleteScheduleModal({ closeModal }) {
  return (
    <>
      <DialogTitle>Tem certeza?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          O horário será deletado e a ação não poderá ser revertida.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="btn-cancel-modal" onClick={() => closeModal()}>
          CANCELAR
        </Button>
        <Button className="btn-save-modal" onClick={() => closeModal(true)}>
          DELETAR
        </Button>
      </DialogActions>
    </>
  );
}
