import styled from "styled-components";

export const TextTitle = styled.div`
  margin-bottom: 2rem;
  border-bottom: 1px solid #e6e6e6;
  h2 {
    color: black;
    margin-bottom: 5px;
    margin-top: 0;
    font-weight: initial;
  }
`;
