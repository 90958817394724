import React from "react";
import { Button, Icon } from "@material-ui/core";
import { WrapperSave } from "./style";

export default function ButtonSave() {
  return (
    <WrapperSave>
      <Button type="submit" className="btn-save" startIcon={<Icon>save</Icon>}>
        SALVAR
      </Button>
    </WrapperSave>
  );
}
