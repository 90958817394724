import React from "react";
import { TextTitle } from "./style";

export default function Title({name}) {
  return (
    <TextTitle>
      <h2>{name}</h2>
    </TextTitle>
  );
}
