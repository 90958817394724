import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import {
  ContainerResetPassword,
  Logo,
  CardResetPassword,
  CardContentResetPassword,
  WrapperCardResetPassword,
  FooterForm,
} from "./style";
import fullLogo from "../../assets/images/new-logo.png";
import { theme } from "../../utils/theme";
import InputPassword from "../../components/InputPassword";
import { comparePasswords } from "../../validators/comparePasswords";
import InputText from "../../components/InputText";
import { toast } from "react-toastify";
import API from "../../utils/api";
import { isMobile } from "react-device-detect";
import InputCpf from "../../components/InputCpf";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [userId, setUserId] = useState(null);

  const { control, handleSubmit, errors } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    if (!userId) {
      data.cpf = data.cpf.replace(/[\s.-]*/gim, "");
      sendCode(data);
    } else {
      resetPassword(data);
    }
  };

  const sendCode = async (data) => {
    setLoading(true);
    try {
      const res = await API.post(
        "/usuarios/recuperar-senha/gerar-codigo",
        data
      );
      toast.success(`${res.data.message}`);
      setUserId(res.data.userId);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (data) => {
    setLoading(true);
    try {
      const res = await API.put(
        `/usuarios/esqueceu-a-senha/alterar-senha/${userId}`,
        data
      );
      toast.success(`${res.data.message}`);
      history.push("/entrar");
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ContainerResetPassword>
        <WrapperCardResetPassword
          style={{ backgroundColor: isMobile ? "#5101f2" : "#00000054" }}
        >
          <CardResetPassword>
            <CardContentResetPassword>
              <Logo>
                <img src={fullLogo} alt="Ligando Ideias" />
              </Logo>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                {!userId && (
                  <InputCpf
                    control={control}
                    rules={{ required: true }}
                    errors={errors}
                  />
                )}

                {userId && (
                  <>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <InputText
                          name="code"
                          label="Código"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          errors={errors}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputPassword
                          name="password"
                          label="Nova senha"
                          control={control}
                          rules={{
                            required: true,
                          }}
                          errors={errors}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <InputPassword
                          name="confirm_password"
                          label="Confirmar Senha"
                          control={control}
                          rules={{
                            required: true,
                            validate: (value) =>
                              comparePasswords(value, control),
                          }}
                          errors={errors}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                <FooterForm>
                  <Link to="/entrar">Voltar para o login</Link>
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    size="large"
                    variant="outlined"
                  >
                    {loading && (
                      <CircularProgress
                        size={20}
                        style={{ color: theme.primary }}
                      />
                    )}
                    {!loading && !userId && "RECUPERAR SENHA"}

                    {!loading && userId && "SALVAR"}
                  </Button>
                </FooterForm>
              </form>
            </CardContentResetPassword>
          </CardResetPassword>
        </WrapperCardResetPassword>
      </ContainerResetPassword>
    </>
  );
}
