import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function InputTime({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  return (
    <Controller
      as={
        <TextField
          variant={variant ? variant : "outlined"}
          fullWidth
          autoComplete="off"
          error={errors[name] ? true : false}
          required={rules && rules.required ? rules.required : false}
          label={label}
          helperText={
            errors[name] && errors[name].type === "required"
              ? "Campo obrigatório"
              : errors[name] && errors[name].type === "validate"
              ? "Campo Inválido"
              : ""
          }
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        ></TextField>
      }
      rules={{
        ...rules,
      }}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
