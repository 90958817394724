import styled from "styled-components";

export const ContainerLoading = styled.div`
    position: fixed;
    background: #0000008c;
    flex-direction: column;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1301;
    height: 100%;
    overflow: hidden;
    color: white;
 
}
`;
