import React, { useState } from "react";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Title from "../../components/Title";
import TableForm from "../../components/TableForm";
import { TotalMoney, TotalMoneyReceived } from "./style";
import API from "../../utils/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { formatMoney } from "../../utils/format/formatMoney";
import { Grid } from "@material-ui/core";
import { IconButton } from "@material-ui/core";

export default function FinancialControl() {
  const columns = [
    { field: "STATUS_PAYMENT", name: "PAGO", type: "status" },
    { field: "NAME", name: "NOME" },
    { field: "PLAN", name: "PLANO" },
    {
      field: "PRICE_PAID",
      name: "VALOR COBRADO",
      prefix: "R$",
      formatValue: (value) => formatMoney(value.toString()),
    },
  ];

  const moreActions = [
    {
      title: "Inserir pagamento",
      icon: "monetization_on",
      color: "rgba(0,0,0,0.54)",
      onClick: (row) => updatePayment(row, 1),
      refreshDataAfterOnClick: true,
    },
    {
      title: "Remover pagamento",
      icon: "remove_circle",
      color: "rgba(0,0,0,0.54)",
      onClick: (row) => updatePayment(row, 0),
      refreshDataAfterOnClick: true,
    },
  ];

  const [total, setTotal] = useState("0,00");
  const [hiddenTotal, setHiddenTotal] = useState(true);

  const [received, setReceived] = useState("0,00");
  const [hiddenReceived, setHiddenReceived] = useState(true);

  const updatePayment = async (row, status_payment) => {
    const data = {
      status_payment,
    };

    try {
      const res = await API.put(`usuarios/atualizar-pagamento/${row.ID}`, data);

      toast.success(`${res.data.message}`);

      getReceivedPrices();
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const getTotalPrices = async () => {
    try {
      const res = await API.get("usuarios/total-preco-alunos");

      if (res?.data?.data) {
        setTotal(formatMoney(res.data.data.toString()));
      }
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const getReceivedPrices = async () => {
    try {
      const res = await API.get("usuarios/total-preco-alunos-recebidos");

      if (res?.data?.data) {
        setReceived(formatMoney(res.data.data.toString()));
      }
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  useEffect(() => {
    getTotalPrices();
    getReceivedPrices();
  }, []);

  return (
    <>
      <Title name="Controle financeiro" />

      <Grid container spacing={4} direction="row">
        <Grid item xs={12} sm={6}>
          <TotalMoney>
            <LocalAtmIcon />
            <span>
              TOTAL:{" "}
              <span className={hiddenTotal ? "hidden-total" : ""}>
                R$ {total}
              </span>
            </span>

            {hiddenTotal ? (
              <IconButton
                color="primary"
                component="span"
                onClick={() => setHiddenTotal((prev) => !prev)}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                component="span"
                onClick={() => setHiddenTotal((prev) => !prev)}
              >
                <VisibilityOffIcon fontSize="small" />
              </IconButton>
            )}
          </TotalMoney>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TotalMoneyReceived>
            <AccountBalanceIcon />
            <span>
              RECEBIDO:{" "}
              <span className={hiddenReceived ? "hidden-received" : ""}>
                R$ {received}
              </span>
            </span>

            {hiddenReceived ? (
              <IconButton
                color="primary"
                component="span"
                onClick={() => setHiddenReceived((prev) => !prev)}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                component="span"
                onClick={() => setHiddenReceived((prev) => !prev)}
              >
                <VisibilityOffIcon fontSize="small" />
              </IconButton>
            )}
          </TotalMoneyReceived>
        </Grid>
      </Grid>

      <TableForm
        url="/usuarios/alunos-ativos"
        columns={columns}
        moreActions={moreActions}
      />
    </>
  );
}
