import styled from "styled-components";

export const AnswerContainer = styled.div`
  .MuiDialogActions-root {
    margin-bottom: 0.5rem;
    justify-content: center;
  }

  .MuiAppBar-colorPrimary {
    position: relative;
    background-color: #2f3a8f;
  }
`;

export const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;

  button.btn-cancel {
    margin-right: 1rem;
  }
`;

export const Attachments = styled.div`
  h2 {
    margin-top: 1rem;
    margin-bottom: 0;
    border-top: 1px solid #e6e6e6;
    padding-top: 1rem;
    span {
      font-size: 13px;
      font-style: italic;
    }
  }
  h4 {
    margin-top: 5px;
    color: red;
    font-size: 13px;
    font-style: italic;
  }
  .MuiButton-containedPrimary {
    background-color: #2f3a8f;
  }
  #upload {
    display: none;
  }
  ul {
    padding-left: 0;
    list-style: none;
    li {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
        cursor: pointer;
        color: red;
      }
    }
  }
`;
