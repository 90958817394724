import styled from "styled-components";

export const FormMyProfilePhotoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;

  .btn-send-photo {
    margin-top: 2rem;
    button {
      background: green;
      color: white;
    }
  }
`;

export const Photo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 211px;
  margin: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid white;

  .imgUser {
    width: 100px;
    height: 100px;
    background-size: cover;
    border-radius: 50%;
    border: 6px solid white;
  }
  .imgIcon {
    width: 100px;
    height: 100px;
    background: #f8f8f8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 4rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }
`;
