import styled from "styled-components";

export const WrapperAdd = styled.div`
  button {
    color: #fff;
    background-color: green;
    border: 1px solid green;
  }
  button:hover {
    color: #fff;
    background-color: green;
    border: 1px solid green;
  }
`;
