import styled from "styled-components";

export const ListSkillOne = styled.div`
  background: #6e2167;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  h2 {
    color: white;
  }
  ul {
    list-style: none;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
`;

export const ListSkillTwo = styled.div`
  background: #c02a63;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  h2 {
    color: white;
  }
  ul {
    list-style: none;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
`;

export const ListSkillThree = styled.div`
  background: #01579c;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  h2 {
    color: white;
  }
  ul {
    list-style: none;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
`;

export const ListSkillFour = styled.div`
  background: #e63405;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  h2 {
    color: white;
  }
  ul {
    list-style: none;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
`;

export const ListSkillFive = styled.div`
  background: #277663;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  h2 {
    color: white;
  }
  ul {
    list-style: none;
    border-radius: 4px;
    background: #fff;
    padding: 10px;
    margin: 0;

    li {
      padding: 10px;

      cursor: pointer;
      &:hover {
        background: #eee;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    }
  }
`;
