import styled from "styled-components";
import { theme } from "../../utils/theme";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import bgLogin from "../../assets/images/bg-login-min.png";

export const ContainerLogin = styled.div`
  .MuiCollapse-container {
    margin-bottom: 2rem !important;
    width: 100%;
  }
  .MuiAlert-standardError {
    display: flex !important;
    justify-content: center !important;
  }

  background-image: url("${bgLogin}");
  height: auto;
  min-height: 100%;
  overflow-y: hidden;
  background-size: cover;
  background-position: center;
`;

export const Logo = styled.div`
  text-align: center;
  img {
    max-width: 75%;
    margin-bottom: 2rem;
  }
`;

export const CardStyled = ({ className, children }) => (
  <Card className={className}>{children}</Card>
);

export const CardLogin = styled(CardStyled)`
  width: 100%;
  max-width: 450px;
`;

export const CardContentStyled = ({ className, children }) => (
  <CardContent className={className}>{children}</CardContent>
);

export const CardContentLogin = styled(CardContentStyled)`
  height: 100%;
  padding: 2rem !important;
`;

export const WrapperCardLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100vh;

  > .MuiPaper-root {
    margin: 2rem;
  }
`;

export const FooterForm = styled.div`
  .MuiCollapse-entered {
    width: 100%;
    margin-bottom: 2rem;
  }
  a {
    margin-bottom: 2rem;
    color: black;
    text-decoration: none;
    font-size: 15px;
  }
  .developed-by {
    margin-top: 2rem;
    color: rgba(154, 164, 191, 0.5) !important;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;

    @media only screen and (max-width: 600px) {
      font-size: 10px;
    }
  }
  a:hover {
    color: ${theme.primary};
  }
  button {
    background: #fff;
    border: 2px solid ${theme.primary};
    color: ${theme.primary};
    font-weight: bold;
    height: 44px;
  }
  button:hover {
    background: ${theme.primary};
    border: 2px solid #fff;
    color: #fff;
    border-radius: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;
