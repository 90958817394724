import React from "react";
import Title from "../../components/Title";
import FormMyProfileData from "../../components/FormMyProfileData";
import FormMyProfilePhoto from "../../components/FormMyProfilePhoto";
import { MyProfileContainer } from "./style";

export default function MyProfile() {
  return (
    <MyProfileContainer>
      <Title name="Meu Perfil" />

      <div className="wrapper-forms">
        <FormMyProfilePhoto />

        <FormMyProfileData />
      </div>
    </MyProfileContainer>
  );
}
