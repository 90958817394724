import styled from "styled-components";

export const TotalMoney = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background: #90ee90;
  padding: 1rem;
  border: 1px solid #487047;

  span {
    color: #487047;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
  }

  span.hidden-total {
    background: #487047;
    color: #487047;
    border-radius: 5px;
  }

  .MuiIconButton-root {
    margin-left: 10px;
  }

  > svg {
    color: #487047;
    margin-right: 7px;
  }
`;

export const TotalMoneyReceived = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background: #487047;
  padding: 1rem;

  span.hidden-received {
    background: white;
    color: white;
    border-radius: 5px;
  }

  span {
    color: white;
    font-size: 18px;
    font-weight: bold;
    padding: 0;
  }

  .MuiIconButton-root {
    margin-left: 10px;
  }

  > svg {
    color: white;
    margin-right: 7px;
  }
`;
