import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Actions, NotFoundData, TableFormContainer } from "./style";
import debounce from "lodash.debounce";
import { MoodBad, Search } from "@material-ui/icons";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Slide,
  TextField,
  Tooltip,
} from "@material-ui/core";
import ButtonAdd from "../../components/ButtonAdd";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import API from "../../utils/api";
import { toast } from "react-toastify";

export default function TableForm({
  url,
  add,
  columns,
  edit,
  deleteItem,
  moreActions,
}) {
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);

  const [modalOpened, setOpen] = useState(false);
  const [itemDeleted, setItemDeleted] = useState({});

  const openModal = (item) => {
    setOpen(true);
    setItemDeleted(item);
  };

  const closeModal = () => {
    setOpen(false);
    setItemDeleted({});
  };

  const getData = useCallback(
    async (page, filter) => {
      setLoading(true);
      let params = {
        page,
      };

      if (filter !== null && filter !== undefined && filter !== "") {
        params.search = filter;
      }

      try {
        const res = await API.get(url, { params });
        setRows(res.data.data);
        setTotal(res.data.total);
      } catch (error) {
        toast.error(
          error && error.data && error.data.error
            ? `${error.data.error}`
            : "Ocorreu um erro."
        );
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  useEffect(() => {
    getData(0);
  }, [getData]);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, filter);
  };

  const onFilter = debounce((text) => {
    setPage(0);
    setFilter(text);
    getData(0, text);
  }, 700);

  const onClickAction = async (action, row) => {
    await action?.onClick(row);
    if (action?.refreshDataAfterOnClick) {
      getData(0);
    }
  };

  return (
    <TableFormContainer>
      <div className="header">
        <div className="search">
          <Search className="iconSearch" />
          <TextField
            fullWidth
            label="Pesquisar"
            onChange={(e) => onFilter(e.target.value)}
          />
        </div>

        {add && <ButtonAdd click={add} />}
      </div>

      <TableContainer style={{ border: "1px solid #e6e6e6" }}>
        <Table size="medium">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.name}
                    align={column.align ? column.align : "center"}
                  >
                    {column.name}
                  </TableCell>
                );
              })}

              {(add || edit || deleteItem || moreActions) && (
                <TableCell key="actions" align="center" style={{ width: 220 }}>
                  AÇÕES
                </TableCell>
              )}
            </TableRow>
            {loading && (
              <TableRow style={{ backgroundColor: "white" }}>
                <TableCell key="loading" colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>

          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  key={row.ID}
                  style={
                    index % 2
                      ? { background: "#e6e6e647" }
                      : { background: "white" }
                  }
                >
                  {columns.map((column) => {
                    const value = row[column.field];
                    if (column.field === "STATUS" || column.type === "status") {
                      return (
                        <TableCell
                          key={column.field}
                          align={column.align ? column.align : "center"}
                        >
                          <div
                            className="status"
                            style={
                              value === 1
                                ? { backgroundColor: "green" }
                                : { backgroundColor: "red" }
                            }
                          ></div>
                        </TableCell>
                      );
                    }

                    if (column.formatValue) {
                      return (
                        <TableCell
                          key={column.field}
                          align={column.align ? column.align : "center"}
                        >
                          {column.prefix
                            ? `${column.prefix} ${column.formatValue(value)}`
                            : `${column.formatValue(value)}`}
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        key={column.field}
                        align={column.align ? column.align : "center"}
                      >
                        {column.prefix ? `${column.prefix} ${value}` : value}
                      </TableCell>
                    );
                  })}

                  {(add || edit || deleteItem || moreActions) && (
                    <TableCell align="center">
                      <Actions>
                        {edit && (
                          <Button size="small" onClick={() => edit(row)}>
                            EDITAR
                          </Button>
                        )}
                        {deleteItem && (
                          <Button
                            size="small"
                            onClick={() => {
                              openModal(row);
                            }}
                          >
                            DELETAR
                          </Button>
                        )}

                        {moreActions?.map((action, index) => {
                          if (action.icon) {
                            return (
                              <Tooltip
                                title={action.title}
                                arrow
                                key={`${action.title}-${index}`}
                              >
                                <IconButton
                                  onClick={() => {
                                    onClickAction(action, row);
                                  }}
                                  style={{ color: action.color }}
                                  component="span"
                                >
                                  <Icon>{action.icon}</Icon>
                                </IconButton>
                              </Tooltip>
                            );
                          } else {
                            return (
                              <Button
                                key={`${action.title}-${index}`}
                                size="small"
                                onClick={() => {
                                  onClickAction(action, row);
                                }}
                              >
                                {action.title}
                              </Button>
                            );
                          }
                        })}
                      </Actions>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length === 0 && !loading && (
          <NotFoundData>
            <MoodBad className="icon" />
            <span>Nenhum dado foi encontrado</span>
          </NotFoundData>
        )}
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={total}
          rowsPerPage={10}
          page={page}
          onPageChange={onChangePage}
        />
      </TableContainer>

      {modalOpened && (
        <Dialog
          TransitionComponent={Transition}
          open={modalOpened}
          onClose={closeModal}
        >
          <DialogTitle>Tem certeza?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              O item será deletado e a ação não poderá ser revertida.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="btn-cancel-modal" onClick={closeModal}>
              CANCELAR
            </Button>
            <Button
              className="btn-save-modal"
              onClick={() => {
                deleteItem(itemDeleted).then(() => {
                  setTimeout(() => {
                    closeModal();
                    getData(page, filter);
                  }, [200]);
                });
              }}
            >
              DELETAR
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </TableFormContainer>
  );
}
