import React from "react";
import {
  Actions,
  NotFoundData,
  TableFormContainer,
  AnswersContainer,
  TitleClass,
} from "./style";

import { Class, DeleteForever, Edit, MoodBad } from "@material-ui/icons";

import {
  Dialog,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useState } from "react";
import { forwardRef } from "react";
import ContentCorrectionModal from "../ContentCorrectionModal";
import DeleteAnswerModal from "../DeleteAnswerModal";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { useDispatch, useSelector } from "react-redux";
import API from "../../utils/api";
import { toast } from "react-toastify";

export default function TableAnswers({ getAllAnswersByPost, data }) {
  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const dispatch = useDispatch();

  const userLogged = useSelector((state) => state.userLogged.id_group);

  const columns = [
    { field: "NAME", name: "ALUNO" },
    { field: "LINK", name: "ARQUIVO" },
    { field: "TIMER", name: "TEMPO GASTO" },
    { field: "NOTE", name: "NOTA" },
  ];

  const [modalCorrection, setModalCorrection] = useState({
    data: null,
    status: false,
  });

  const [deleteAnswerModal, setDeleteAnswerModal] = useState({
    answer: null,
    status: false,
  });

  const openModalCorrection = (data) => {
    setModalCorrection({
      data,
      status: true,
    });
  };

  const closeModalCorrection = (close) => {
    setModalCorrection({
      data: null,
      status: false,
    });
    getAllAnswersByPost();

    if (close?.rotate) {
      const filter = data
        .map((k) => k.ANSWERS.filter((e) => e.ID === close.idRotate))
        .filter(({ length }) => length);

      const row = filter[0][0];

      row.LINK = close.urlRotate;

      setTimeout(() => {
        openModalCorrection(row);
      }, 2000);
    }
  };

  const openDeleteAnswerModal = (answer) => {
    setDeleteAnswerModal({ status: true, answer });
  };

  const closeDeleteAnswerModal = (close) => {
    if (close) {
      deleteAnswer(deleteAnswerModal.answer);
    } else {
      setDeleteAnswerModal({ status: false, answer: null });
    }
  };

  const deleteAnswer = async (answer) => {
    try {
      dispatch(showLoading());
      const res = await API.delete(`/posts/deletar-resposta/${answer.ID}`);
      toast.success(`${res.data.message}`);
      getAllAnswersByPost();
      setDeleteAnswerModal({ status: false, answer: null });
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
      dispatch(hideLoading());
    }
  };

  return (
    <AnswersContainer>
      {data?.map((itemClass) => {
        return (
          <div className="itemClass" key={itemClass.ID_CLASS}>
            <TableFormContainer>
              <TableContainer
                style={{
                  border: "1px solid #e6e6e6",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                }}
              >
                <Table size="medium">
                  <TableHead>
                    <TableRow style={{ backgroundColor: "white" }}>
                      <TableCell
                        colSpan={columns.length + 1}
                        className="titleClass"
                      >
                        <TitleClass>
                          <Class />
                          <h2>{itemClass.NAME}</h2>
                        </TitleClass>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            key={column.name}
                            align={column.align ? column.align : "center"}
                          >
                            {column.name}
                          </TableCell>
                        );
                      })}

                      <TableCell
                        key="actions"
                        align="center"
                        style={{ width: 220 }}
                      >
                        AÇÕES
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {itemClass.ANSWERS.map((row, index) => {
                      return (
                        <TableRow
                          key={row.ID_USER}
                          style={
                            index % 2
                              ? { background: "#e6e6e647" }
                              : { background: "white" }
                          }
                        >
                          {columns.map((column) => {
                            const value = row[column.field];
                            if (column.field === "LINK") {
                              return (
                                <TableCell
                                  key={column.field}
                                  align={column.align ? column.align : "center"}
                                >
                                  <a
                                    href={value}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    anexo
                                  </a>
                                </TableCell>
                              );
                            } else if (column.field === "NOTE") {
                              let newValue;
                              if (value) {
                                newValue = JSON.parse("[" + value + "]");
                              }

                              return (
                                <TableCell
                                  key={column.field}
                                  align={column.align ? column.align : "center"}
                                >
                                  {value
                                    ? newValue.reduce((a, b) => a + b, 0)
                                    : "-"}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.field}
                                  align={column.align ? column.align : "center"}
                                >
                                  {value}
                                </TableCell>
                              );
                            }
                          })}
                          <TableCell align="center">
                            <Actions>
                              <Tooltip title="Corrigir" aria-label="Corrigir">
                                <Edit
                                  className="correct"
                                  onClick={() => openModalCorrection(row)}
                                />
                              </Tooltip>

                              {userLogged && userLogged === 1 && (
                                <Tooltip title="Deletar" aria-label="Deletar">
                                  <DeleteForever
                                    className="delete"
                                    onClick={() => openDeleteAnswerModal(row)}
                                  />
                                </Tooltip>
                              )}
                            </Actions>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {itemClass.ANSWERS.length === 0 && (
                  <NotFoundData>
                    <MoodBad className="icon" />
                    <span>Nenhuma atividade foi encontrada</span>
                  </NotFoundData>
                )}
              </TableContainer>
            </TableFormContainer>
          </div>
        );
      })}

      {modalCorrection && modalCorrection.status && (
        <Dialog
          fullScreen
          open={modalCorrection.status}
          TransitionComponent={Transition}
        >
          <ContentCorrectionModal
            title={data[0]?.TITLE.toUpperCase()}
            data={modalCorrection.data}
            closeModal={(close) => closeModalCorrection(close)}
          ></ContentCorrectionModal>
        </Dialog>
      )}

      {deleteAnswerModal && deleteAnswerModal.status && (
        <Dialog open={deleteAnswerModal.status}>
          <DeleteAnswerModal
            closeModal={(close) => closeDeleteAnswerModal(close)}
          ></DeleteAnswerModal>
        </Dialog>
      )}
    </AnswersContainer>
  );
}
