import React from "react";
import { Button, Icon } from "@material-ui/core";
import { WrapperCancel } from "./style";

export default function ButtonCancel({click}) {
  return (
    <WrapperCancel>
      <Button
        className="btn-cancel"
        startIcon={<Icon>cancel</Icon>}
        onClick={click ? click : () => {}}
      >
        FECHAR
      </Button>
    </WrapperCancel>
  );
}
