import { FormControl, FormHelperText } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import { valueNotEmpty } from "../../validators/blank";
import { TextAreaContainer } from "./style";

export default function TextArea({
  name,
  placeholder,
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  return (
    <TextAreaContainer>
      <FormControl
        variant={variant ? variant : "outlined"}
        fullWidth
        required={rules && rules.required ? rules.required : false}
      >
        <Controller
          as={
            <textarea
              placeholder={placeholder}
              className={errors[name] ? "error" : ""}
            ></textarea>
          }
          rules={{
            ...rules,
            validate: (value) => valueNotEmpty(value, control, name),
          }}
          control={control}
          name={name}
          defaultValue={defaultValue ? defaultValue : ""}
        />
        {errors[name] && errors[name].type === "required" && (
          <FormHelperText error>Campo obrigatório</FormHelperText>
        )}
        {errors[name] && errors[name].type === "validate" && (
          <FormHelperText error>Campo em branco</FormHelperText>
        )}
      </FormControl>
    </TextAreaContainer>
  );
}
